<template>
    <div class="device-wrap">
        <div class="device-info">
            <Title name='项目基本信息' :operate="true">
                <div style="display:flex;align-items:center;color:#999;cursor:pointer" @click="info = !info">
                    <span>{{info ? '收起': '展开'}}</span>
                    <i style="font-size:16px" :class="[info ?  'el-icon-caret-top' : 'el-icon-caret-bottom' ]"></i>
                </div>
            </Title>

            <div style="padding:20px" v-show="info">
                <el-form ref='form' :inline="true" :model="formData" label-position="right" label-width="130px"
                    :rules="rules">
                    <el-form-item label="项目名称" prop="projName">
                        <el-input :disabled='isDisbled !== "update"' v-model="formData.projName" placeholder="请输入项目名称">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="项目所在地" prop="projAddress">
                        <el-input :disabled='isDisbled !== "update"' v-model="formData.projAddress"
                            placeholder="请输入项目所在地"></el-input>
                    </el-form-item>
                    <el-form-item label="开发商" prop="developer">
                        <el-input :disabled='isDisbled !== "update"' v-model="formData.developer" placeholder="请输入开发商">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="承建商" prop="constractor">
                        <el-input :disabled='isDisbled !== "update"' v-model="formData.constractor"
                            placeholder="请输入承建商"></el-input>
                    </el-form-item>
                    <el-form-item label="合同签约单位" prop="contractEntp">
                        <el-input :disabled='isDisbled !== "update"' v-model="formData.contractEntp"
                            placeholder="请输入合同签约单位"></el-input>
                    </el-form-item>
                    <el-form-item label="合同编号" prop="contractNo">
                        <el-input :disabled='isDisbled !== "update"' v-model="formData.contractNo"
                            placeholder="请输入合同编号"></el-input>
                    </el-form-item>
                    <el-form-item label="销售模式" prop="sellMode">
                        <el-select :disabled='isDisbled !== "update"' v-model="formData.sellMode" placeholder="请选择销售模式">
                            <el-option v-for="item in sell_mode" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="铝膜栋数" prop="lvDs">
                        <el-input type="number" :disabled='isDisbled !== "update"' v-model="formData.lvDs"
                            placeholder="请输入铝膜栋数"></el-input>
                    </el-form-item>
                    <el-form-item label="单层面积" prop="areaEc">
                        <el-input type="number" :disabled='isDisbled !== "update"' v-model="formData.areaEc"
                            placeholder="请输入单层面积" style="width:180px"></el-input>
                        <div class="tags">平分米 / m²</div>
                    </el-form-item>
                    <el-form-item label="层数" prop="lvCs">
                        <el-input type="number" :disabled='isDisbled !== "update"' v-model="formData.lvCs"
                            placeholder="请输入层数"></el-input>
                    </el-form-item>
                    <el-form-item label="项目类别" prop="projType">
                        <el-select :disabled='isDisbled !== "update"' v-model="formData.projType" placeholder="请选择项目类别">
                            <el-option v-for="item in projectType" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="业务负责人" prop="projLeader">
                        <el-input :disabled='isDisbled !== "update"' v-model="formData.projLeader"
                            placeholder="请输入业务负责人"></el-input>
                    </el-form-item>
                    <el-form-item label="铝模板综合单价" prop="lvPrices">
                        <el-input type="number" :disabled='isDisbled !== "update"' v-model="formData.lvPrices"
                            placeholder="请输入铝模板综合单价" style="width:180px"></el-input>
                        <div class="tags">元/方</div>
                    </el-form-item>
                    <el-form-item label="合同金额" prop="contractAmt">
                        <el-input type="number" :disabled='isDisbled !== "update"' v-model="formData.contractAmt"
                            placeholder="请输入合同金额"></el-input>
                    </el-form-item>
                    <el-form-item label="租期" prop="rentTerm">
                        <el-input type="number" :disabled='isDisbled !== "update"' v-model="formData.rentTerm"
                            placeholder="请输入租期" style="width:180px"></el-input>
                        <div class="tags">月</div>
                    </el-form-item>
                    <el-form-item label="合同进场时间" prop="addTime">
                        <el-date-picker :disabled='isDisbled !== "update"' v-model="formData.addTime" type="date"
                            value-format="yyyy-MM-dd" placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="上传合同文件" :required='true'>
                        <el-upload class="upload-demo" :action="url" accept='.PDF' :on-preview="handlePreview"
                            :disabled='isDisbled !== "update"' :on-remove="handleRemove" :on-success='uploadSuccess'
                            :before-remove="beforeRemove" :limit="5" :on-exceed="handleExceed" :file-list="fileList">
                            <el-button size="small" type="primary" :disabled='isDisbled !== "update"'>选择文件</el-button>
                            <div slot="tip" class="el-upload__tip">温馨提示：上传格式为PDF格式</div>
                        </el-upload>
                    </el-form-item>
                </el-form>

            </div>
        </div>

        <div class="device-oneself">
            <Title name='已关联设备' :operate="true">
                <div style="display:flex;align-items:center;color:#999;cursor:pointer"
                    @click="myDevice_show = !myDevice_show">
                    <span>{{myDevice_show ? '收起': '展开'}}</span>
                    <i style="font-size:16px"
                        :class="[myDevice_show ?  'el-icon-caret-top' : 'el-icon-caret-bottom' ]"></i>
                </div>
            </Title>
            <div v-show="myDevice_show">
                <div class="search">
                    <span>经销商：</span>
                    <el-input style="width:20%;margin:0 20px 0 0" v-model="myDevice_queryList.dealer" size="small"
                        placeholder="请输入内容">
                    </el-input>
                    <span>设备名称：</span>
                    <el-input style="width:20%;margin:0 20px 0 0" v-model="myDevice_queryList.trackerName" size="small"
                        placeholder="请输入内容">
                    </el-input>
                    <span>型号：</span>
                    <el-input style="width:20%;margin:0 20px 0 0" v-model="myDevice_queryList.model" size="small"
                        placeholder="请输入内容">
                    </el-input>
                    <el-button type="primary" size="small" @click="queryList('myDevice')">查询</el-button>
                </div>

                <div style="padding:0 10px">
                    <el-table :data="myDevicee_listData" style="width: 100%,"
                        :header-cell-style="{'background':'#f5f5f5','font-weight': 500,'color':'#666','padding':'12 5px','text-align':'center'}">

                        <el-table-column align='center' prop="dealer" label="经销商" width="200"></el-table-column>
                        <el-table-column align='center' prop="trackerName" label="设备名称" width="270"></el-table-column>
                        <el-table-column align='center' prop="model" label="型号"></el-table-column>
                        <el-table-column align='center' prop="imei" label="设备卡号"></el-table-column>
                        <el-table-column align='center' prop="status" label="设备状态"></el-table-column>
                        <el-table-column align='center' prop="formattedAddress" label="地址" width="250">
                        </el-table-column>
                    </el-table>
                    <div class="pagination">
                        <el-pagination background layout="prev, pager, next" :total='myDevicee_pagination.total'
                            :page-size='10' :current-page.sync="myDevicee_pagination.currentPage"
                            @current-change="changeCurrentPage('myDevicee')">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>

        <div class="device-associate">
            <Title name='可关联设备' :operate="true">
                <div style="display:flex;align-items:center;color:#999;cursor:pointer"
                    @click="device_associateShow = !device_associateShow">
                    <span>{{device_associateShow ? '收起': '展开'}}</span>
                    <i style="font-size:16px"
                        :class="[device_associateShow ?  'el-icon-caret-top' : 'el-icon-caret-bottom' ]"></i>
                </div>
            </Title>

            <div v-show="device_associateShow">
                <div class="search">
                    <span>经销商：</span>
                    <el-input style="width:20%;margin:0 20px 0 0" v-model="associate_queryList.dealer" size="small"
                        placeholder="请输入内容">
                    </el-input>
                    <span>设备名称：</span>
                    <el-input style="width:20%;margin:0 20px 0 0" v-model="associate_queryList.trackerName" size="small"
                        placeholder="请输入内容">
                    </el-input>
                    <span>型号：</span>
                    <el-input style="width:20%;margin:0 20px 0 0" v-model="associate_queryList.model" size="small"
                        placeholder="请输入内容">
                    </el-input>
                    <el-button type="primary" size="small" @click="queryList('associate')">查询</el-button>
                </div>

                <div style="padding:0 10px">
                    <el-table ref='tables' :data="associate_listData" style="width: 100%," @select-all="selectAll"
                        v-loading="loading" @select='selects'
                        :header-cell-style="{'background':'#f5f5f5','font-weight': 500,'color':'#666','padding':'12 5px','text-align':'center'}">

                        <el-table-column align='center' type="selection" width="55" v-if="isDisbled == 'associate'">
                        </el-table-column>
                        <el-table-column align='center' prop="dealer" label="经销商" width="200"></el-table-column>
                        <el-table-column align='center' prop="trackerName" label="设备名称" width="270"></el-table-column>
                        <el-table-column align='center' prop="model" label="型号"></el-table-column>
                        <el-table-column align='center' prop="imei" label="设备卡号"></el-table-column>
                        <el-table-column align='center' prop="status" label="设备状态"></el-table-column>
                        <el-table-column align='center' prop="formattedAddress" label="地址" width="250">
                        </el-table-column>
                    </el-table>
                    <div class="pagination">
                        <el-pagination background layout="prev, pager, next" :total='associate_pagination.total'
                            :page-size='10' :current-page.sync="associate_pagination.currentPage"
                            @current-change="changeCurrentPage('associate')">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>

        <div style="text-align:center;margin-top:20px">

            <el-button type="primary" @click="submitForm" v-if="isDisbled !== 'check'">保存</el-button>
        </div>


    </div>
</template>

<script>
    import Title from '../../../component/Title'
    import {
        checkEquipment,
        upDateEquipment,
        associateEquipment,
        getAllTrackerByUserId,
        batchInsertContractLink,
        getTrackListByContractId,
        uploadPath
    } from '../../../api'
    export default {
        created() {
            if (this.$route.query.type == 'update') this.info = true

            if (this.$route.query.type == 'associate') this.device_associateShow = true, this.loading = true

            if (this.$route.query.type == 'check') {
                this.device_associateShow = true
                this.info = true
                this.myDevice_show = true
                this.loading = true
            }
            var _this = this
            checkEquipment({
                id: this.$route.query.id
            }).then(e => {
                if (e.errno == 0) {
                    var contractFile
                    var contractFileName
                    var fileList = []
                    var formData = {}
                    Object.keys(_this.formData).forEach(key => {
                        formData[key] = e.data[key]
                    })
                    _this.formData = formData
                    contractFile = e.data.contractFile.split(",")
                    contractFileName = e.data.contractFileName.split(",")
                    contractFile.forEach((e, index) => {
                        fileList.push({
                            name: contractFileName[index],
                            url: e
                        })
                    })
                    _this.fileList = fileList
                } else {
                    this.$message({
                        message: e.errmsg,
                        type: 'error'
                    })
                }
            })

            async function awaitfun() {
                _this.getMyDevicee({})
                await _this.getTrackListByContractId()
                _this.getAssociate({})

            }

            awaitfun()


        },
        data() {
            return {
                loading: false,
                myDevicee_list: [],
                device_associateShow: false,
                associate_queryList: {
                    dealer: '',
                    trackerName: '',
                    model: '',
                    contractId: this.$route.query.id,
                },
                associate_query: {},
                associate_listData: [],
                associate_all: [],
                associate_pagination: {
                    total: 0,
                    currentPage: 0,
                },
                myDevice_show: false,
                myDevice_queryList: {
                    dealer: '',
                    trackerName: '',
                    model: '',
                    contractId: this.$route.query.id,
                },
                myDevice_query: {},
                myDevicee_listData: [],
                myDevicee_pagination: {
                    total: 0,
                    currentPage: 0,
                },
                initial_selected: [],
                info: false,
                isDisbled: this.$route.query.type ? this.$route.query.type : '',
                formData: {
                    projName: '',
                    projAddress: '',
                    developer: '',
                    constractor: '',
                    contractEntp: '',
                    contractNo: '',
                    sellMode: '',
                    lvDs: '',
                    areaEc: '',
                    lvCs: '',
                    projType: '',
                    projLeader: '',
                    lvPrices: '',
                    contractAmt: '',
                    rentTerm: '',
                    addTime: '',
                },
                rules: {
                    projName: [{
                        required: true,
                        message: '请输入项目名称',
                        trigger: 'blur'
                    }],
                    projAddress: [{
                        required: true,
                        message: '请输入项目所在地',
                        trigger: 'blur'
                    }],
                    developer: [{
                        required: true,
                        message: '请输入开发商',
                        trigger: 'blur'
                    }],
                    constractor: [{
                        required: true,
                        message: '请输入承建商',
                        trigger: 'blur'
                    }],
                    contractEntp: [{
                        required: true,
                        message: '请输入合同签约单位',
                        trigger: 'blur'
                    }],
                    contractNo: [{
                        required: true,
                        message: '请输入合同编号',
                        trigger: 'blur'
                    }],
                    sellMode: [{
                        required: true,
                        message: '请选择销售模式',
                        trigger: 'blur'
                    }],
                    lvDs: [{
                        required: true,
                        message: '请输入铝膜栋数',
                        trigger: 'blur'
                    }],
                    areaEc: [{
                        required: true,
                        message: '请输入单层面积',
                        trigger: 'blur'
                    }],
                    lvCs: [{
                        required: true,
                        message: '请输入层数',
                        trigger: 'blur',

                    }],
                    projType: [{
                        required: true,
                        message: '请选择项目类别',
                        trigger: 'blur'
                    }],
                    projLeader: [{
                        required: true,
                        message: '请输入业务负责人',
                        trigger: 'blur'
                    }],
                    lvPrices: [{
                        required: true,
                        message: '请输入铝模板综合单价',
                        trigger: 'blur'
                    }],
                    contractAmt: [{
                        required: true,
                        message: '请输入合同金额',
                        trigger: 'blur'
                    }],
                    rentTerm: [{
                        required: true,
                        message: '请输入租期',
                        trigger: 'blur'
                    }],
                    addTime: [{
                        required: true,
                        message: '请选择合同进场时间',
                        trigger: 'blur'
                    }],
                },
                sell_mode: [{
                    value: 'SELL',
                    label: '销售'
                }, {
                    value: 'RENT',
                    label: '出租'
                }],
                projectType: [{
                    value: 'ZZ',
                    label: '住宅'
                }, {
                    value: 'SY',
                    label: '商用'
                }, {
                    value: 'XZL',
                    label: '写字楼'
                }, {
                    value: 'ORTHER',
                    label: '其他'
                }],
                fileList: [],
                url: uploadPath,
            }
        },
        methods: {
            // 获取全部已关联设备
            getTrackListByContractId() {
                var _this = this
                var contractId = this.$route.query.id
                return getTrackListByContractId({
                    contractId
                }).then(e => {
                    if (e.errno == 0) {
                        // 转换数组结构，用于方便找出相同项
                        function transformMap(arr, key) {
                            let map = {}
                            let tempKey = ""
                            // 转换为 key: value 结构
                            arr.forEach(item => {
                                tempKey = item[key]
                                map[tempKey] = item
                            })
                            return map
                        }

                        _this.associate_all = transformMap(e.data, 'imei')

                        console.log( _this.associate_all)
                    }
                })
            },

            // 获取已关联设备（分页）
            getMyDevicee(query, pageNum = 1, pageSize = 10) {
                query.contractId = this.$route.query.id
                return getAllTrackerByUserId({
                    ...query,
                    pageNum,
                    pageSize
                }).then(e => {
                    if (e.errno == 0) {
                        var change = {
                            NOTACTIVE: '未激活',
                            NORMAL: '已激活',
                            INVALID: '已到期'
                        }
                        this.myDevicee_listData = e.data.list.map(value => {
                            value.status = change[value.validStatus]
                            value.chooseFlag = true
                            return value
                        })

                        this.myDevicee_list = e.data.list.map(value => {
                            return {
                                imei: value.imei,
                                trackerName: value.trackerName
                            }
                        })
                        this.myDevicee_pagination.total = e.data.total
                        // debugger
                        return
                    }
                    this.$message({
                        message: e.errmsg,
                        type: 'error'
                    })
                })
            },

            // 获取可关联设备
            getAssociate(queryList, pageNum = 1, pageSize = 10) {
                var _this = this
                this.loading = true
                queryList.contractId = this.$route.query.id
                return associateEquipment({
                    ...queryList,
                    pageNum,
                    pageSize
                }).then(e => {
                    this.loading = false
                    if (e.errno == 0) {
                        var change = {
                            NOTACTIVE: '未激活',
                            NORMAL: '已激活',
                            INVALID: '已到期'
                        }

                        _this.associate_listData = e.data.list.map(value => {
                            value.status = change[value.validStatus]
                            return value
                        })

                        _this.$nextTick(e => {
                            _this.associate_listData.forEach(row => {
                                // console.log(row.imei)
                                // console.log(this.associate_all)
                                if(_this.associate_all.length == 0){
                                    debugger
                                }
                                if (_this.associate_all[row.imei]) {
                                    // console.log(row.imei)
                                    _this.$refs.tables.toggleRowSelection(row);
                                } 
                            });
                        })

                        _this.associate_pagination.total = e.data.total
                        return
                    }
                    _this.$message({
                        message: e.errmsg,
                        type: 'error'
                    })
                })
            },

            // 查询设备
            queryList(e) {
                if (e == 'associate') {
                    this.associate_query = {
                        ...this.associate_queryList
                    }
                    this.associate_pagination.currentPage = 1
                   
                    this.getAssociate(this.associate_query)
                } else {
                    this.myDevice_query = {
                        ...this.myDevice_queryList
                    }
                    this.myDevicee_pagination.currentPage = 1
                    this.getMyDevicee(this.myDevice_query)
                }

            },

            // 提交信息
            submitForm() {
                if (this.$route.query.type == 'update') {
                    var _this = this
                    this.$refs.form.validate((valid) => {
                        if (valid) {
                            if (!_this.fileList.length) return _this.$message({
                                message: '请选择上传合同文件后，再提交保存'
                            })
                            var formData = _this.formData
                            var fileList = _this.fileList

                            formData.contractFile = fileList[0].url
                            formData.contractFileName = fileList[0].name

                            for (var i = 1; i < fileList.length; i++) {
                                formData.contractFile = formData.contractFile + ',' + fileList[i].url
                                formData.contractFileName = formData.contractFileName + ',' + fileList[i].name
                            }
                            formData.id = this.$route.query.id
                            upDateEquipment(formData).then(e => {
                                if (e.errno == 0) {
                                    _this.$message({
                                        message: '修改成功',
                                        type: 'success'
                                    });

                                    this.$router.push({
                                        path: '/User/device?customer=buyder'
                                    })
                                    return
                                }
                                this.$message({
                                    message: e.errmsg,
                                    type: 'error'
                                });
                            })
                        }
                    });
                } else {
                    var associate_all = this.associate_all
                    var selected = Object.keys(associate_all).map(e =>{
                        if(associate_all[e]){
                            return {
                                contractId: this.$route.query.id,
                                imei: associate_all[e].imei,
                                trackerName: associate_all[e].trackerName
                            }
                        }
                    })

                    batchInsertContractLink({
                        contractId: this.$route.query.id,
                        trackerVoList: selected
                    }).then(e => {
                        if (e.errno == 0) {
                            this.$message({
                                message: '关联成功',
                                type: 'success'
                            })
                            this.$router.push({
                                path: '/User/device?customer=buyder'
                            })
                            return
                        }
                        this.$message({
                            message: e.errmsg,
                            type: 'error'
                        })
                    })
                }
            },

            // 移除上传文件后
            handleRemove(file, fileList) {
                this.fileList = fileList
                // console.log(file, fileList);
            },

            // 点击下载
            handlePreview(file) {
                window.open(file.url)
            },

            // 处理上传文件
            handleExceed(files, fileList) {
                this.$message.warning(
                    `最多可选择 5 个文件上传`);
            },

            // 移除上传文件前
            beforeRemove(file, fileList) {
                return this.$confirm(`确定移除 ${ file.name }？`);
            },

            //分页跳转
            changeCurrentPage(type) {
                if (type == 'associate') {
                    this.getAssociate(this.associate_query, this.associate_pagination.currentPage)
                } else {

                    this.getMyDevicee(this.myDevice_query, this.myDevicee_pagination.currentPage)
                    // debugger
                }
            },

            // 操作可关联设备
            selects(selection, row) {

                var associate_all = {...this.associate_all}
                var imei = row.imei
                if(associate_all[imei]){
                    associate_all[imei] = false
                } else {
                    associate_all[imei] = row
                }
                this.associate_all = associate_all
            },

            // 全选
            selectAll(val) {
                var associate_all = {...this.associate_all}
                if (!val.length) {
                    // 取消全部
                    // 获取当前页数据
                    var associate_listData = this.associate_listData

                    associate_listData.forEach(row => {
                        if (associate_all[row.imei]) {
                            associate_all[row.imei] = false
                        }
                    });
                } else {
                    // 选择全部
                    val.forEach(row => {
                        associate_all[row.imei] = row
                    });
                }
                this.associate_all = associate_all
            },

            uploadSuccess(response, file, fileList) {
                if (response.errno == 0) {
                    this.fileList = fileList
                    this.fileList[fileList.length - 1].url = response.data.url
                    return
                }
                console.log('上传结果', response)
            }
        },
        components: {
            Title
        }
    }
</script>

<style lang="less" scoped>
    .device-wrap {

        // background: #fff;
        .device-info {
            background: #fff;
        }

        .device-oneself {
            background: #fff;
            margin-top: 20px;

            .search {
                padding: 20px 0 20px 20px;

                span {
                    color: #999;
                }
            }

            .pagination {
                padding: 20px;
                box-sizing: border-box;
                text-align: right;
            }
        }

        .device-associate {
            background: #fff;
            margin-top: 20px;

            .search {
                padding: 20px 0 20px 20px;

                span {
                    color: #999;
                }
            }

            .pagination {
                padding: 20px;
                box-sizing: border-box;
                text-align: right;
            }
        }
    }


    .tags {
        display: inline-block;
        width: 90px;
        margin-left: 10px;
        padding-left: 10px;
        border: 1px solid #C0C4CC;
        box-sizing: border-box;
        height: 40px;
    }

    // // 取消表格标题处的全选框

    // /deep/.el-table__header-wrapper {
    //     .el-checkbox__inner {
    //         display: none;
    //     }
    // }


    /deep/.el-form-item {
        margin-bottom: 30px;
    }


    // 去掉数字输入框右边的上下箭头
    /deep/input::-webkit-outer-spin-button,
    /deep/input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    /deep/input[type="number"] {
        -moz-appearance: textfield;
    }

    /deep/.el-input {
        width: 280px;
    }

    /deep/.el-form-item {
        width: 48%;
    }
</style>